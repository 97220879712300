interface DialogOptions {
  url: string;
  height?: number;
  width?: number;
  onMessage?: (message: string) => void;
  onClose?: () => void;
  onError?: (error: any) => void;
}

let dialog: Office.Dialog | null = null;

const openDialog = (options: DialogOptions) => {
  const {
    url,
    height = 70,
    width = 30,
    onMessage = () => { },
    onClose = () => { },
    onError = () => { },
  } = options;

  if (options.url === '') {
    return;
  }

  // if (true) {
  // In a regular web browser environment, use window.open
  const popup = window.open(url, '_blank', `width=${width},height=${height}`);

  if (popup) {
    // Listen for messages from the popup
    window.addEventListener('message', (event) => {
      // Validate the origin of the message if needed
      if (event.origin === window.location.origin) {
        onMessage(event.data); // Call the provided message handler with the popup message
        popup.close();
      }
    });

    // Poll for popup closure
    const popupCheckInterval = setInterval(() => {
      if (popup.closed) {
        clearInterval(popupCheckInterval);
        onClose(); // Call the onClose handler when the popup is closed
      }
    }, 500);
  } else {
    onError(new Error('Failed to open popup'));
  }
  // } else {
  //   // In an Office environment, use the Office Dialog API
  //   if (Office?.context?.ui?.displayDialogAsync !== null
  //     && Office?.context?.ui?.displayDialogAsync !== undefined) {
  //     Office.context.ui.displayDialogAsync(
  //       url,
  //       { height, width, promptBeforeOpen: false },
  //       (asyncResult: Office.AsyncResult<Office.Dialog>) => {
  //         if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
  //           dialog = asyncResult.value;
  //           dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: any) => {
  //             onMessage(arg.message);
  //           });
  //           dialog.addEventHandler(Office.EventType.DialogEventReceived, onClose);
  //         } else {
  //           onError(asyncResult.error);
  //         }
  //       }
  //     );
  //   } else {
  //     onError(new Error('Office.context.ui.displayDialogAsync is not available.'));
  //   }
  // }
};

const closeDialog = () => {
  if (dialog) {
    dialog.close();
    dialog = null;
  }
};

export { openDialog, closeDialog };
