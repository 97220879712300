import React, {
  useState, FC, useCallback,
} from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import Button from '@fv-components/button';

// FV API
import { IProject, IProjectSearchPayload } from '../util-api/models/project';
import {
  logout,
  getCurrentOrg,
} from '../Auth/auth';

// Utilities
import useApolloClient from '../util-api/apolloClient';
import useCountRenders from '../util-hooks/useCountRenders';

// Child Components
import EphemeralId from '../EphemeralId';
import ProjectContext, { ICurrentProject, initialProjectSearchState } from '../Taskpane/ProjectContext';
import ProjectWorkspace from './ProjectWorkspace';
import ProjectSearchBar from '../Taskpane/ProjectSearchBar';
import ProjectSearchResults from '../Taskpane/ProjectSearchResults';

// Styles
import '../App.scss';
import FilevineIcon from '../Taskpane/FilevineIcon';
import GenericCard from '../Taskpane/ProjectWorkspace/GenericCard';
import CreateProjectForm from '../Taskpane/CreateProject';
import SearchLimitWarning from '../Taskpane/SearchLimitWarning';

const css = require('./OfficeTaskpane.module.scss');

const Taskpane: FC = () => {
  useCountRenders('Taskpane');

  const [currentProject, setCurrentProject] = (
    useState<React.SetStateAction<ICurrentProject | undefined>>(undefined)
  );
  const [projectSearchResults, setProjectSearchResults] = useState<IProjectSearchPayload>(
    initialProjectSearchState,
  );

  const { client } = useApolloClient();

  const clearCurrentProject = useCallback(() => {
    if (currentProject) {
      setCurrentProject(undefined);
    }
  }, [currentProject]);

  const [isProjectCreateCard, setIsProjectCreateCard] = useState(false);
  const currentOrg = getCurrentOrg();
  const [searchTerm, setSearchTerm] = useState<string>();

  const projectsMatchOrg = !projectSearchResults?.items?.length
      || projectSearchResults.items[0].orgId === currentOrg?.orgId;

  return client ? (
    <ApolloProvider client={client}>
      <ProjectContext.Provider value={{
        currentProjectData: currentProject ? (currentProject as ICurrentProject).data : undefined,
        currentProjectId: currentProject ? (currentProject as ICurrentProject).id : undefined,
        projectSearchResults,
        setCurrentProject,
        setProjectSearchResults,
      }}
      >
        <EphemeralId />
        <div
          className={[
            css.taskpaneContainer,
            currentProject ? css.viewportHeight : '',
          ].join(' ')}
        >

          <div className={css.taskpaneHeader}>
            {/* Org Selection and Project Search */}
            <ProjectSearchBar
              clearCurrentProject={clearCurrentProject}
              onProjectSearchTerm={(term?: string) => {
                if (term !== searchTerm) setSearchTerm(term);
              }}
            />

            {currentOrg?.pdfEditEnabled
              ? (
                <>
                  {/* Create Project */}
                  {!currentProject && (
                  <GenericCard
                    buttonIcon={(
                      <FilevineIcon
                        icon="plus"
                        className={css.icon}
                      />
                )}
                    buttonText="Create Project"
                    outlined={false}
                    child={(
                      <CreateProjectForm
                        orgName={currentOrg!.name}
                        onProjectCreateSuccess={(p: IProject) => (
                          setCurrentProject({ data: p, id: p.projectId.native }))}
                      />
                )}
                    onOpen={() => setIsProjectCreateCard(true)}
                    onClose={() => isProjectCreateCard && setIsProjectCreateCard(false)}
                    genCardButtonClass={css.createProjectCard}
                    dataTestUniq="createProjectForm"
                  />
                  )}

                  {/* Project Search Results Count */}
                  {!currentProject && projectsMatchOrg && (!projectSearchResults.hasMore ? (
                    <div className={css.searchBarResultsCount}>
                      {searchTerm && projectSearchResults ? `Project search results: ${projectSearchResults.count}` : `Recent projects: ${projectSearchResults.count}`}
                    </div>
                  ) : (
                    <SearchLimitWarning limit={projectSearchResults.limit} />
                  ))}
                </>
              )
              : (
                <div className={css.notDocsPlus}>
                  <span>
                    <img src="assets/fv-logo.png" alt="Filevine" />
                  </span>
                  <span>
                    The selected Org does not have Docs+ enabled.
                    Contact your account representative to upgrade to Docs+ or select another org.
                  </span>

                </div>
            )}
          </div>

          {currentOrg?.pdfEditEnabled && (
            <>
              {/* Project Search Results or Project Workspace */}
              {!currentProject && projectsMatchOrg && <ProjectSearchResults />}
              {currentProject && <ProjectWorkspace />}
            </>
          )}

          {/* Sign Out */}
          {(!currentProject && !isProjectCreateCard) && (
            <Button
              className={css.signOutBtn}
              onClick={() => logout()}>
              Sign Out
            </Button>
          )}
        </div>
      </ProjectContext.Provider>
    </ApolloProvider>
  ) : <></>;
};

export default Taskpane;
