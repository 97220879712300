import React, {
  FC, useContext, useState, useRef, useEffect,
} from 'react';
import DocumentContext from './DocumentContext';

// FV API
import useMutateRecentProjects from '../../util-api/useMutateRecentProjects';
import useCountRenders from '../../util-hooks/useCountRenders';
import { getUserId } from '../../Auth/auth';

// Child Components
import ProjectContext from '../../Taskpane/ProjectContext';
import Contacts from '../../Taskpane/ProjectWorkspace/Contacts';

import ProjectHeader from '../../Taskpane/ProjectWorkspace/ProjectHeader';
import Team from '../../Taskpane/ProjectWorkspace/Team';
import ProjectWorkspaceNavigation from './ProjectWorkspaceNavigation';
import SaveAs from './SaveAs';
import Activity from './DocumentActivity';
import { IDocument } from '../../util-api/models/document';
import { IFolder } from '../../util-api/useQueryFolderList';
import { getIsExcel, getIsPowerPoint, getIsWord } from '../../util-helpers/office';
import SaveAsContext from './SaveAs/SaveAsContext';

// Styles
const cssCommon = require('../../Common.module.scss');
const css = require('../../Taskpane/ProjectWorkspace/ProjectWorkspace.module.scss');

interface ITabContentItem {
  name: string;
  element: JSX.Element;
}

const ProjectWorkspace: FC = () => {
  useCountRenders('ProjectWorkspace');
  const { currentProjectData, currentProjectId } = useContext(ProjectContext);
  const { updateRecentProjects } = useMutateRecentProjects();

  const [currentDoc, setCurrentDocument] = useState<IDocument | undefined>();
  const [currentFolder, setCurrentFolder] = useState<IFolder>();
  const [parentFolders, setParentFolders] = useState<IFolder[]>([]);
  const [extension, setExtension] = useState(() => {
    if (getIsWord()) return '.docx';
    if (getIsExcel()) return '.xlsx';
    if (getIsPowerPoint()) return '.pptx';
    return '';
  });
  const [filename, setFilename] = useState(currentDoc?.filename || `${extension}`);

  const [activeTabIdx, setActiveTabIdx] = useState(0);
  const handleActiveTabUpdate = (idx: number) => setActiveTabIdx(idx);

  const scrollContainer = useRef<HTMLDivElement>(null);
  const scrollTopRef = useRef<HTMLDivElement>(null);
  const scrollBottomRef = useRef<HTMLDivElement>(null);

  const scrollTo = (pos: 'top' | 'bottom') => {
    if (pos === 'bottom' && scrollBottomRef && scrollBottomRef.current) {
      scrollBottomRef.current.scrollIntoView({ behavior: 'auto' });
    }
    if (pos === 'top' && scrollTopRef && scrollTopRef.current) {
      scrollTopRef.current.scrollIntoView({ behavior: 'auto' });
    }
  };

  useEffect(() => {
    if (activeTabIdx === 1) {
      scrollTo('bottom');
    } else {
      scrollTo('top');
    }
  }, [activeTabIdx]);

  useEffect(() => {
    const userId = getUserId();
    if (userId && currentProjectId) {
      updateRecentProjects(userId, currentProjectId);
    }
  }, [currentProjectId, updateRecentProjects]);

  const tabContentItems: ITabContentItem[] = [
    {
      name: 'Save As',
      element: <SaveAs />,
    },
    {
      name: 'Activity',
      element: (
        <Activity
          currentUserId={getUserId()}
          scrollContainer={scrollContainer}
          currentProjectId={currentProjectId as number}
        />
      ),
    },
    {
      name: 'Contacts',
      element: <Contacts
        isActiveTab={activeTabIdx === 2}
        projectId={currentProjectId}
        projectEmail={currentProjectData?.projectEmailAddress}
      />,
    },
    {
      name: 'Team',
      element: <Team
        isActiveTab={activeTabIdx === 3}
        projectId={currentProjectId}
      />,
    },
  ];

  return (
    <DocumentContext.Provider value={{
      currentDoc,
      currentDocId: currentDoc?.documentId?.native,
      setCurrentDocument,
    }}
    >
      <SaveAsContext.Provider value={{
        currentFolder,
        extension,
        filename,
        parentFolders,
        setCurrentFolder,
        setExtension,
        setFilename,
        setParentFolders,
      }}
      >
        <div className={css.projectWorkspaceHeader}>
          <ProjectHeader />
          <ProjectWorkspaceNavigation
            activeTabIdx={activeTabIdx}
            handleActiveTabUpdate={handleActiveTabUpdate}
          />
        </div>
        <div className={css.projectWorkspaceContent} ref={scrollContainer}>
          <div ref={scrollTopRef} />
          <>
            {tabContentItems.map((c: ITabContentItem, i: number) => (
              <div
                key={c.name}
                className={i === activeTabIdx ? '' : cssCommon.hidden}
              >
                {c.element}
              </div>
            ))}
          </>
          <div ref={scrollBottomRef} />
        </div>
      </SaveAsContext.Provider>

    </DocumentContext.Provider>
  );
};

export default ProjectWorkspace;
