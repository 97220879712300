import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { IProject, IProjectSearchPayload } from './models/project';

export interface IQueryRecentProjectsHook {
  recentProjectsResults: IProjectSearchPayload,
  getRecentProjects: (userId: string) => void;
}

export const RECENT_PROJECTS_QUERY = gql`
  query recentProjectsQuery {
    recentProjects(userId: $userId) 
      @rest(type: "Projects", path: "/users/{args.userId}/recentprojects") {
        clientId
        clientName
        firstPrimaryName
        firstPrimaryUsername
        hashtags
        isArchived
        lastActivity
        links
        orgId
        phaseName
        phaseId
        projectEmailAddress
        projectId
        projectName
        projectOrClientName
        projectUrl
        projectTypeCode
        projectTypeId
        uniqueKey
        rootDocFolderId
      }
  }`;

const useQueryRecentProjects = (): IQueryRecentProjectsHook => {
  const [executeQuery, {
    data, error, loading, called,
  }] = useLazyQuery<{recentProjects: IProject[]}>(
    RECENT_PROJECTS_QUERY, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    },
  );

  const getRecentProjects = useCallback(
    (userId: string) => executeQuery(
      { variables: { userId } },
    ), [executeQuery],
  );

  return {
    recentProjectsResults: {
      called,
      error,
      loading,
      count: 0,
      items: [],
      hasMore: false,
      limit: 50, // recent projects does not take or return a limit and is fixed at 50
      ...(!!data?.recentProjects?.length && {
        hasMore: !!(data?.recentProjects?.length >= 50), // also does not return has more
        count: data.recentProjects.length,
        items: data.recentProjects,
      }),
    },
    getRecentProjects,
  };
};

export default useQueryRecentProjects;
