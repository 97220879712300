import React, { FC, useState, useEffect } from 'react';
import Button from '@fv-components/button';

import { getConfig } from '../../config';
import { getIsOutlook } from '../../util-helpers/office';
import { setLocalStorage } from '../../util-helpers/common';
import EphemeralId from '../../EphemeralId';
import { closeDialog, openDialog } from '../../util-helpers/officeDialog';

const css = require('./Landing.module.scss');

const Landing: FC = () => {
  const [isLoginComplete, setIsLoginComplete] = useState(false);

  const { hostUrl } = getConfig();

  const processLoginProperties = (args: any) => {
    // console.log("Received message from login", args);
    // unpack variables here
    const obj = JSON.parse(args?.message);
    setLocalStorage(obj);
    // once done
    setIsLoginComplete(true);
  };

  useEffect(() => {
    if (isLoginComplete) {
      closeDialog();
      window.location.assign(hostUrl);
    }
  }, [isLoginComplete, hostUrl]);

  useEffect(() => {
    if (window.location.pathname === "/signout") {
      window.location.assign("/logout");
    }
  }, []);

  const isOutlook = getIsOutlook();

  const defaultMsgTitle = 'Sign in with your Filevine account.';
  const defaultMsgDesc = isOutlook ? 'The Filevine Add-in provides a window into your Filevine projects from inside Outlook. Quickly send emails to your Filevine project, add activities, and reference project data, all without leaving Outlook.'
    : 'The Filevine Add-in provides a window into your Filevine projects from inside Office. Quickly save documents, spreadsheets, or presentations to your Filevine project, add activities, and reference project data, all without leaving Office.';
  const ctaMessage = defaultMsgTitle;
  // eslint-disable-next-line no-nested-ternary
  const ctaDescription = defaultMsgDesc;

  return <>
    <div className={css.loginContainer}>
      <EphemeralId />
      <img src="assets/fv-logo.png" alt="Filevine" />
      <span className={css.loginCTA}>
        {ctaMessage}
      </span>
      <Button
        id="login_button_fvid"
        raised
        type="submit"
        className={css.loginButton}
        data-test="sign-in"
        onClick={() => {
          openDialog({
            url: `${process.env.REACT_APP_HOST}/login`,
            onMessage(message) {
              processLoginProperties({ message });
            },
            height: 700,
            width: 500
          });
        }}>
        Sign In
      </Button>
      <small>
        <div>
          {ctaDescription}
        </div>
        <br />
        <br />
        <div>
          Don’t have a Filevine account?
        </div>
        <a href="https://www.filevine.com/demo-request?utm_source=outlook" target="_blank" rel="noopener noreferrer">Schedule your demo today.</a>
      </small>
    </div>
  </>;
};

export default Landing;
