import { useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import IProjectContact from './models/projectContact';

interface IProjectContacts {
  count: number;
  offset: number;
  limit: number;
  hasMore: boolean;
  requestedFields: string;
  items: IProjectContact[];
}

interface IProjectContactsResponse {
  contacts: IProjectContacts;
}

interface IProjectContactsPayload {
  loading: boolean;
  error?: ApolloError;
  contacts?: IProjectContacts;
  called: boolean;
}

export interface IQueryProjectContactsHook {
  projectContactsSearchResults: IProjectContactsPayload,
  getProjectContacts: (
    projectId: number, searchTerm: string, limit?: number
  ) => void,
  getMoreProjectContacts: (
    projectId: number, searchTerm: string, limit?: number
  ) => void,
}

export const CONTACTS_QUERY = gql`
  query contactsQuery {
    contacts(id: $id, searchTerm: $searchTerm, limit: $limit, offset: $offset)
      @rest(type: "Contacts", path: "/projects/{args.id}/contacts?limit={args.limit}&searchTerm={args.searchTerm}&offset={args.offset}") {
        count
        offset
        limit
        hasMore
        requestedFields
        items
    }
  }`;

const useQueryProjectContacts = (): IQueryProjectContactsHook => {
  const [executeQuery, {
    data, error, loading, called, fetchMore,
  }] = useLazyQuery<IProjectContactsResponse>(
    CONTACTS_QUERY, { notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' },
  );

  const getProjectContacts = useCallback(
    (
      id: number, searchTerm: string, limit: number = 50,
    ) => executeQuery(
      {
        variables: {
          id, searchTerm: encodeURIComponent(searchTerm), limit, offset: 0,
        },
      },
    ), [executeQuery],
  );

  const getMoreProjectContacts = useCallback(
    (
      projectId: number, searchTerm: string, limit: number = 50,
    ) => {
      if (fetchMore && data && data.contacts && data.contacts.hasMore && !loading) {
        fetchMore({
          variables: {
            offset: data && data.contacts ? data.contacts.items.length : 0,
            projectId,
            searchTerm: encodeURIComponent(searchTerm),
            limit,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return {
              ...prev,
              contacts: {
                ...prev.contacts,
                items: [
                  ...prev.contacts.items,
                  ...fetchMoreResult.contacts.items,
                ],
                hasMore: fetchMoreResult.contacts.hasMore,
              },
            };
          },
        });
      }
    }, [fetchMore, data, loading],
  );

  return {
    projectContactsSearchResults: {
      error,
      loading,
      contacts: data && data.contacts,
      called,
    },
    getProjectContacts,
    getMoreProjectContacts,
  };
};

export default useQueryProjectContacts;
