import { Auth } from 'aws-amplify';
import {
  getConfig, getTenantConfig, setTenantConfig, setUserDomain, setUserTenant,
} from '../config';
import { nrPageAction } from '../util-helpers/newrelic';
import { LocalStorageKey } from '../util-helpers/common';
import FVID from './FVID';



interface IUserId {
  userId: {
    native: number;
    partnerId: string;
  }
}

export interface IUserOrg {
  name: string;
  orgId: number;
  pdfEditEnabled: boolean;
}

interface IUserOrgOptions {
  label: string;
  value: number;
}

interface IUserAndOrgId {
  user: IUserId;
  orgs: IUserOrg[];
}

interface ICurrentUser {
  user: {
    userId: {
      native: number;
    };
  };
  userName: string;
  isActive: boolean;
  email: boolean;
}

const getAccessToken = async (): Promise<string> => {
  const fvidAccessToken = await FVID.getFVIDAccessToken();
  if (fvidAccessToken) {
    return fvidAccessToken;
  }
  const session = await Auth.currentSession();
  const token = await session.getAccessToken().getJwtToken();
  return token;
};

const getUserId = (): string => (
  localStorage.getItem(LocalStorageKey.UserId) || ''
);

const setUserId = (userId: number | string) => (
  localStorage.setItem(LocalStorageKey.UserId, userId.toString())
);

const getUserOrgs = (): IUserOrg[] => {
  const orgs = localStorage.getItem(LocalStorageKey.UserOrgs);
  return orgs ? JSON.parse(orgs) : [];
};

const getUserOrgsAsOptions = (): IUserOrgOptions[] => getUserOrgs()?.map(
  (o) => ({ label: o.name, value: o.orgId }),
);

const setUserOrgs = (userOrgs: IUserOrg[]) => (
  localStorage.setItem(LocalStorageKey.UserOrgs, JSON.stringify(userOrgs))
);

const getOrgId = (): number | null => {
  const id = localStorage.getItem(LocalStorageKey.OrgId);
  return id ? +id : null;
};

const getCurrentOrg = (): IUserOrg | undefined => {
  const orgId = getOrgId();
  return getUserOrgs().find((o) => o.orgId === orgId);
};

const setOrgId = (orgId: number | string) => {
  localStorage.setItem(LocalStorageKey.OrgId, orgId.toString());
};

const setSAMLAuth = (SAMLName: string) => {
  localStorage.setItem(LocalStorageKey.SAMLAuthProvider, SAMLName);
};

const getSAMLAuth = () => localStorage.getItem(LocalStorageKey.SAMLAuthProvider);

const handleAmplifyConfigure = async (): Promise<void> => {
  const {
    appClientID, awsRegion, cognitoUserPoolID, tenantID,
  } = await getTenantConfig();

  const authConfig = {
    userPoolId: cognitoUserPoolID,
    userPoolWebClientId: appClientID,
    mandatorySignIn: true,
    oauth: {},
  };

  if (getSAMLAuth()) {
    const { hostUrl } = getConfig();
    authConfig.oauth = {
      domain: `${tenantID}.auth.${awsRegion}.amazoncognito.com`,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: `${hostUrl}/success`,
      responseType: 'code',
    };
  }

  Auth.configure({
    Auth: authConfig,
  });
};

const logout = () => {
  setUserId('');
  setOrgId('');
  setUserOrgs([]);
  setUserDomain('');
  setUserTenant('');
  setTenantConfig('');
  setSAMLAuth('');
  if (FVID.getUseFVID()) {
    FVID.logout();
  } else {
    Auth.signOut().then(() => {
      const { hostUrl } = getConfig();
      window.location.assign(hostUrl);
    });
  }
};

const fetchUserDetails = async (
  session: string, userId: number, orgId: number, filevineApiBaseUrl: string,
): Promise<ICurrentUser> => {
  const response = await fetch(`${filevineApiBaseUrl}/users/me`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${session}`,
      'x-fv-clientIp': '',
      'x-fv-orgId': `${orgId}`,
      'x-fv-userId': `${userId}`,
      'x-fv-application': 'outlook-addin',
    },
  });

  if (!response.ok) {
    const responseStatus = `${response.status}`;
    const errorCode = 'FVOA:fetchUserDetails:1';
    nrPageAction('outlookAuth', {
      message: 'Error fetching user details from Filevine',
      errorCode,
      responseStatus,
      requestData: `userId: ${userId} orgId: ${orgId} filevineApiBaseUrl: ${filevineApiBaseUrl}`,
    });
    const userErrorMsg = `There was an error fetching user details from Filevine. Error:${errorCode} Status:${responseStatus} From:${filevineApiBaseUrl}`;
    // eslint-disable-next-line no-console
    console.error(userErrorMsg);
    throw new Error(userErrorMsg);
  }

  const user = await response.json();
  return user;
};

const fetchUserIdAndOrgs = async (
  session: string, filevineApiBaseUrl: string,
): Promise<IUserAndOrgId> => {
  const response = await fetch(`${filevineApiBaseUrl}/utils/GetUserOrgsWithToken`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${session}`,
    },
  });

  if (!response.ok) {
    const responseStatus = `${response.status}`;
    const errorCode = 'FVOA:fetchUserIdAndOrgs:1';
    nrPageAction('outlookAuth', {
      message: 'Error fetching user and org id from Filevine',
      errorCode,
      responseStatus,
      requestData: `filevineApiBaseUrl: ${filevineApiBaseUrl}`,
    });
    const userErrorMsg = `There was an error fetching your user and org id from Filevine. Error:${errorCode} Status:${responseStatus} From:${filevineApiBaseUrl}`;
    // eslint-disable-next-line no-console
    console.error(userErrorMsg);
    throw new Error(userErrorMsg);
  }

  const userOrgs = await response.json();
  return userOrgs;
};

const fetchAndSetUserAndOrgId = async (session: string) => {
  // Get API URL from config
  const { filevineApiBaseUrl } = await getConfig();
  // Throw and error is no API URL
  if (!filevineApiBaseUrl || filevineApiBaseUrl.length < 1) {
    const errorCode = 'FVOA:setUserAndOrg:1';
    nrPageAction('outlookAuth', {
      message: 'Cant fetch user and org id from Filevine without API Url', errorCode, filevineApiBaseUrl,
    });
    const userErrorMsg = `Cant fetch user and org id from Filevine without API Url. Error:${errorCode}`;
    // eslint-disable-next-line no-console
    console.error(userErrorMsg);
    throw new Error(userErrorMsg);
  }

  // Fetch user and org id
  const userOrgAndId: IUserAndOrgId = await fetchUserIdAndOrgs(session, filevineApiBaseUrl);
  // Throw an error if no user or orgs
  const userId = userOrgAndId?.user?.userId?.native;
  const orgs = userOrgAndId?.orgs;
  if (!orgs || !userId) {
    const errorCode = 'FVOA:setUserAndOrg:2';
    nrPageAction('outlookAuth', { message: 'Error no user or org id returned from Filevine', errorCode });
    const userErrorMsg = `There was an error setting your Filevine user or org id. Error:${errorCode}`;
    // eslint-disable-next-line no-console
    console.error(userErrorMsg);
    throw new Error(userErrorMsg);
  }

  // Throw an error if no org(s)
  if (!orgs.length) {
    const errorCode = 'FVOA:setUserAndOrg:3';
    nrPageAction('outlookAuth', { message: 'User has no orgs', errorCode });
    const userErrorMsg = `This feature only supports active subscribed Filevine users. If you believe this to be in error, please contact your organization administrator to be properly configured within the organization. Error:${errorCode}`;
    // eslint-disable-next-line no-console
    console.error(userErrorMsg);
    throw new Error(userErrorMsg);
  }

  // Good user w/ 1 or more orgs
  userOrgAndId.orgs.sort(
    (a: IUserOrg, b: IUserOrg) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1),
  );
  setUserOrgs(orgs);
  setUserId(userId);

  // Only set a default orgId if they don't already have one thats a valid org
  const userOrgId = getOrgId();
  if (!userOrgId || !(orgs.findIndex((x) => x.orgId === userOrgId) > -1)) {
    setOrgId(orgs[0].orgId);
  }

  // If orgId is still not set Throw an Error
  const orgId = getOrgId();
  if (!orgId) {
    const errorCode = 'FVOA:setUserAndOrg:4';
    nrPageAction('outlookAuth', { message: 'Error getting a valid Filevine org id', errorCode });
    const userErrorMsg = `There was an error getting a valid Filevine org id. Error:${errorCode}`;
    // eslint-disable-next-line no-console
    console.error(userErrorMsg);
    throw new Error(userErrorMsg);
  }

  // Check the user is valid and active. Validate Token has access.
  const user = await fetchUserDetails(
    session, userId, orgId, filevineApiBaseUrl,
  );
  // Throw error if no user
  if (!user) {
    const errorCode = 'FVOA:setUserAndOrg:5';
    nrPageAction('outlookAuth', { message: 'No user returned', errorCode });
    const userErrorMsg = `There was an error getting a valid Filevine user. Error:${errorCode}`;
    // eslint-disable-next-line no-console
    console.error(userErrorMsg);
    throw new Error(userErrorMsg);
  }
  // Throw error if user is not active
  if (!user?.isActive) {
    const errorCode = 'FVOA:setUserAndOrg:6';
    nrPageAction('outlookAuth', { message: 'Returned user is not active', errorCode });
    const userErrorMsg = `Active user account not returned from Filevine. Error:${errorCode}`;
    // eslint-disable-next-line no-console
    console.error(userErrorMsg);
    throw new Error(userErrorMsg);
  }

  return true;
};

const updateUserFromSession = async (): Promise<void> => {
  // Get user session from Auth
  let session: string = "";
  try {
    if (FVID.getUseFVID()) {
      const fvidUserData = localStorage.getItem("user");
      if (fvidUserData) {
        session = JSON.parse(fvidUserData)?.access_token;
      }
    } else {
      const data = await Auth.currentSession();
      session = data.getIdToken().getJwtToken();
    }
  } catch (error) {
    const errorCode = 'FVOA:updateUserFromSession:1';
    nrPageAction('outlookAuth', { message: 'Unable to get current session.', errorCode });
    const userErrorMsg = `Unable to get current session. Error:${errorCode}`;
    // eslint-disable-next-line no-console
    console.error(userErrorMsg);
    throw new Error(userErrorMsg);
  }

  // Fetch and set user and org id
  await fetchAndSetUserAndOrgId(session);
};

const validateUserAuthentication = async () => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch {
    return false;
  }
};

export {
  getCurrentOrg,
  getUserId,
  setUserId,
  getUserOrgs,
  setUserOrgs,
  getUserOrgsAsOptions,
  getOrgId,
  setOrgId,
  getAccessToken,
  logout,
  setSAMLAuth,
  handleAmplifyConfigure,
  fetchUserDetails,
  fetchUserIdAndOrgs,
  fetchAndSetUserAndOrgId,
  updateUserFromSession,
  validateUserAuthentication,
};
